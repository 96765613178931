import React, { PureComponent } from 'react';

import { Input } from 'antd';

export default class InputText extends PureComponent {
    handleChange = (ev) => {
        this.props.onChange(ev.target.value);
    }
    render() {
        const maxlength = this.props.maxlength || (this.props.validator.length !== undefined ? this.props.validator.length.max : this.props.maxlength);
        return (
            <Input
                style={{width: this.props.width, textAlign: this.props.align, maxWidth: maxlength ? `${maxlength + 3}em` : ''}}
                disabled={this.props.disabled}
                size={this.props.size}
                value={this.props.value}
                placeholder={this.props.placeholder}
                autoComplete={this.props.autoComplete}
                prefix={this.props.prefix}
                suffix={this.props.suffix}
                maxLength={maxlength}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
                onChange={this.handleChange}  />
        )
    }
}
InputText.defaultProps = {
    autoComplete: "zoo-off", // Chrome ignores "off", "false" etc. an invalid value prevents auto complete
    validator: {},
    onFocus: () => {},
    onBlur: () => {},
};