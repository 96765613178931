import React, { PureComponent } from 'react';
import memoizeOne from 'memoize-one'

import { Card } from 'antd';
import { toString } from 'helpers/utils';

class InputCards extends PureComponent {
    options = memoizeOne((values, labels) => {
        return values.map((v) => ({
            value: v,
            label: this.props.output
                ? this.props.output({value: v})
                : (labels[v] || v),
        }));
    })
    handleChange(value) {
        this.props.onChange(value);      
    }
    render() {
        const { values, labels, value, size, align, empty, emptyText, clear, clearText } = this.props
        if (!values.length && empty) {
            return (
                <Card
                    className={`select select__${size} select__disabled`}>
                    {emptyText}
                </Card>
            );
        }
        return <>
            <div className="col col__gap-small">
                {this.options(values, labels).map(option => {
                    return (
                        <Card
                            className={`select select__${size} ${toString(option.value) === toString(value) ? 'is-selected' : ''}`}
                            style={{textAlign: align}}
                            key={toString(option.value)}
                            onClick={() => this.handleChange(option.value)}>
                            {option.label}
                        </Card>
                    );
                })}
                {clear && <>
                    <Card
                        className={`select select__${size} ${toString(null) === toString(value) ? 'is-selected' : ''}`}
                        style={{textAlign: align}}
                        key={toString(null)}
                        onClick={() => this.handleChange(null)}>
                        {clearText}
                    </Card>
                </>}
            </div>
        </>;
    }
}
InputCards.defaultProps = {
    values: [],
    labels: {},
    empty: true,
    emptyText: '—',
    clear: false,
    clearText: 'None',
};
export default InputCards;