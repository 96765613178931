import React, { PureComponent } from 'react';

import { Switch as AntSwitch } from 'antd';

export default class InputSwitch extends PureComponent {
    handleChange(value) {
        this.props.onChange(value);
    }
    render() {        
        return (
            <AntSwitch
                disabled={this.props.disabled}
                checked={this.props.value}
                onChange={(value) => this.handleChange(value)} />
        )
    }
}