import React, { useState, useEffect, useRef, useContext } from 'react';
import { Card, Result, Row, Col, Modal, Button, Divider } from 'antd';
import { UserOutlined, QuestionCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { pdfjs, Document, Page } from 'react-pdf';

import AppContext from 'app/context';
import config from 'app/config';
import { withApi, getUri, useApi, useLog } from 'helpers/api';
import { compose } from 'helpers/utils';
import Loading from './common/Loading';
import Error from './common/Error';
import Form from './common/Form';

pdfjs.GlobalWorkerOptions.workerSrc = `${config.basePath}/pdfjs/pdf.worker.js`;
const pdfOptions = {
    cMapUrl: `${config.basePath}/pdfjs/cmaps/`,
    cMapPacked: true,
};

const typeLabels = {
	contract: 'Contract',
	permanent: 'Permanent',
};

const ir35StatusLabels = {
	pending: 'Pending',
	in: 'Inside',
	out: 'Outside',
};

const Requirement = ({ entities: records, refresh }) => {
	const [ requirement ] = records;
	const { connectAgree } = requirement;
	useLog({
		action: 'access',
		requirement: requirement.id,
	});
	return <>
		{connectAgree ? <>
			<Present requirement={requirement} onReject={() => refresh()} />
		</> : <>
			<Agree requirement={requirement} onAgree={() => refresh()} />
		</>}
	</>;
}
export default Requirement;

const Agree = ({ requirement, onAgree }) => {
	const [ , agree ] = useApi({
        method: 'PUT',
        url: `requirement-v2:connect/${requirement.id}/agreeConnect`,
		data: {
            records: [
                {
                    id: requirement.id,
                },
            ],
        },
	}, { manual: true });
	const handleAgree = () => {
		agree().then(() => {
			onAgree();
		});
	};
	return <>
		<Result
    		icon={<QuestionCircleOutlined />}
			extra={<>
				<AgreeText requirement={requirement} />
				<Button type="primary" onClick={handleAgree}>
					I Agree
				</Button>
			</>} />
	</>;
}

const AgreeText = ({ requirement }) => {
	const { connectAgreeMode, connectAgreeTerms } = requirement;
	const [ viewTerms, setViewTerms ] = useState(false);
	if (connectAgreeMode === 'defaultTerms') {
		return <>
			<div className="ant-result-title">By continuing you are agreeing to our <span className="link" onClick={() => setViewTerms(true)}>Terms of Business</span></div>
			<p className="ant-result-subtitle">Please click link to view Terms of Business</p>
			{viewTerms && <AgreeTerms
				onCancel={() => setViewTerms(false)}
				url={`${config.basePath}/client-terms-of-business.pdf`} />}
		</>;
	} else if (connectAgreeMode === 'defaultStatement') {
		return <>
			<div className="ant-result-title">Working to previously agreed TOB</div>
			<p className="ant-result-subtitle">Please click to accept.</p>
		</>;
	} else if (connectAgreeMode === 'customTerms') {
		const url = getUri({
			url: `/file-v2:connect/${connectAgreeTerms.id}`,
			params: { format: 'pdf' },
		});
		return <>
			<div className="ant-result-title">By continuing you are agreeing to our <span className="link" onClick={() => setViewTerms(true)}>Terms of Business</span>?</div>
			<p className="ant-result-subtitle">Please click link to view Terms of Business.</p>
			{viewTerms && <AgreeTerms
				onCancel={() => setViewTerms(false)}
				url={url} />}
		</>;
	}
}

export const AgreeTerms = ({ url, onCancel }) => {
	return <>
		<Modal
			visible
			onCancel={onCancel}
			className="document"
			centered
			footer={null}>
			<Pdf url={url} />
		</Modal>
	</>;
}

const AgreeLink = ({ requirement }) => {
	const { connectAgreeMode, connectAgreeTerms } = requirement;
	const [ viewTerms, setViewTerms ] = useState(false);
	if (connectAgreeMode === 'defaultTerms') {
		return <>
			<span className="link" onClick={() => setViewTerms(true)}>View</span>
			{viewTerms && <AgreeTerms
				onCancel={() => setViewTerms(false)}
				url={`${config.basePath}/client-terms-of-business.pdf`} />}
		</>;
	} else if (connectAgreeMode === 'customTerms') {
		const url = getUri({
			url: `/file-v2:connect/${connectAgreeTerms.id}`,
			params: { format: 'pdf' },
		});
		return <>
			<span className="link" onClick={() => setViewTerms(true)}>View</span>
			{viewTerms && <AgreeTerms
				onCancel={() => setViewTerms(false)}
				url={url} />}
		</>;
	}
}

const Present = ({ requirement, onReject }) => {
	const { jobTitle, company, links = [], type, connectAgreeMode, connectAnonymous, ir35Status, initialContractDuration } = requirement;
	const [ link, setLink ] = useState(null);
	const { setPresenting } = useContext(AppContext);
	useEffect(() => {
		setPresenting(true);
	});



	return <>
		<div className="row">
			<div>
				<h1 className="spreadheading">{jobTitle}</h1>
				<p className="subtitle">{company.name}</p>
			</div>
			<div>
				<dl className="meta">
					<dt>Type</dt>
						<dd>{typeLabels[type]}</dd>
					{type === 'contract' && <>
						<dt>IR35</dt>
							<dd>{ir35StatusLabels[ir35Status] || '—'}</dd>
						<dt>Duration</dt>
							<dd>{initialContractDuration || '—'}</dd>
					</>}
					{(connectAgreeMode === 'defaultTerms' || connectAgreeMode === 'customTerms') && <>
						<dt>TOB</dt>
							<dd><AgreeLink requirement={requirement} /></dd>
					</>}
				</dl>
			</div>
		</div>
		<Row gutter={[24, 24]}>
			{links.map((link, i) => {
				const { candidate } = link;
				return <React.Fragment key={i}>
					<Col span={8}>
						<Card>
							<h2 style={{ textAlign: 'center', marginBottom: '0.75em' }}>{connectAnonymous ? candidate.id.toString().padStart(5, '0') : candidate.name}</h2>
							<Button block icon={<UserOutlined />} onClick={() => setLink(link)}>View Candidate</Button>
						</Card>
					</Col>
				</React.Fragment>;
			})}
		</Row>
		{link && <Link
			requirement={requirement}
			link={link}
			key={link.id}
			onReject={onReject}
			onCancel={() => setLink(null)} />}
	</>;
}

export const Link = ({ requirement, link, onReject, onCancel }) => {
	const [ response, setResponse ] = useState(null);
	const { candidate, lives, availability, essentialSkills, connectNotes } = link;
	const { connectAnonymous } = requirement;
	useLog({
		action: 'view',
		requirement: requirement.id,
		link: link.id,
	});
	const url = getUri({
		url: `/file-v2:connect/${candidate.cv.id}`,
		params: { format: 'pdf' },
	});
	return <>
		<Modal
			visible
			onCancel={onCancel}
			className="full"
			centered
			footer={null}>
			<div className="split">
				<div className="split_sidebar">
					<h2 className="spreadheading">{connectAnonymous ? candidate.id.toString().padStart(5, '0') : candidate.name}</h2>
					<Divider />
					<div className="responses">
						<Button onClick={() => setResponse('interview')}>
							Arrange an Interview 
						</Button>
						{requirement.type === 'contract' ? <>
							<Button onClick={() => setResponse('offerContract')}>
								Make an Offer
							</Button>
						</> : <>
							<Button onClick={() => setResponse('offerPermanent')}>
								Make an Offer
							</Button>
						</>}
						<Button onClick={() => setResponse('message')}>
							Send us a Message  
						</Button>
						<Button onClick={() => setResponse('reject')}>
							Reject 
						</Button>
					</div>
					<Divider />
					<div className="info">
						{lives && <>
							<h3>Lives</h3>
							<p>{lives}</p>
						</>}
						{availability && <>
							<h3>Availability</h3>
							<p>{availability}</p>
						</>}
						{essentialSkills && <>
							<h3>Essential Skills</h3>
							<div dangerouslySetInnerHTML={{__html: essentialSkills}} />
						</>}
						{connectNotes && <>
							<h3>Notes</h3>
							<p>{connectNotes}</p>
						</>}
					</div>
				</div>
				<div className="split_content">
					<Pdf url={url} />
				</div>
			</div>
		</Modal>
		{response && <Response
			requirement={requirement}
			link={link}
			key={response}
			type={response}
			onReject={onReject}
			onCancel={() => setResponse(null)} />}
	</>;
}

const Response = ({ requirement, link, type, onReject, onCancel }) => {
	const [ data, setData ] = useState({});
	const [ { loading, error } , submit ] = useApi({
        method: 'POST',
        url: `requirement_connect-response-v2:connect`,
		data: {
            records: [
                {
                    requirement: requirement.id,
                    link: link.id,
                    type: type,
					...data,
                },
            ],
        },
	}, { manual: true });
	const handleChange = (value) => {
		setData(value);
	};
	const handleSubmit = () => {
		submit().then(() => {
			Modal.success({
				title: 'Request Sent',
				content: 'Thank you for submitting your request, we will be in touch with you shortly.',
				okText: 'Back to Candidate',
			});
			if (type === 'reject') {
				onReject();
			}
			onCancel();
		});
	};
	const forms = {
		interview: FormInterview,
		offerContract: FormOfferContract,
		offerPermanent: FormOfferPermanent,
		reject: FormReject,
		message: FormMessage,
	};
	const Form = forms[type];
	return <>
		<Modal
			visible
			onCancel={onCancel}
			footer={<>
				<Button type="primary" loading={loading} onClick={handleSubmit}>Submit</Button>
			</>}
			centered>
			<Form
				requirement={requirement} link={link}
				errors={error ? error.response.data.data.errors : {}}
				value={data}
				onChange={handleChange} />
		</Modal>
	</>;
}

const FormInterview = ({ value, onChange, ...pass }) => {
	return <>
		<h2>Arrange an Interview</h2>
		<Form spec={{
			properties: {
				dateOption1: {
					label: '1st Date Option',
					type: 'datetime',
				},
				dateOption2: {
					label: '2nd Date Option',
					type: 'datetime',
				},
				dateOption3: {
					label: '3rd Date Option',
					type: 'datetime',
				},
				method: {
					label: 'Interview Method',
					type: 'option',
					values: [
						'Face to Face',
						'Teams',
						'Skype',
						'Telephone',
					]
				},
				contact: {
					label: 'What is your preferred contact method?',
					type: 'option',
					values: [
						'Email',
						'Telephone',
						'Teams',
					]
				},
			},
			render: ({ r }) => <>
				{r('dateOption1')}
				{r('dateOption2')}
				{r('dateOption3')}
				{r('method')}
				<Divider />
				{r('contact')}
			</>
		}} value={value} onChange={onChange} {...pass} />
	</>;
}

const FormOfferContract = ({ requirement, onChange, ...pass }) => {
	return <>
		<h2>Make an Offer</h2>
		<Form spec={{
			properties: {
				message: {
					label: 'Make an offer on this candidate',
					type: 'textLong',
				},
				contact: {
					label: 'What is your preferred contact method?',
					type: 'option',
					values: [
						'Email',
						'Telephone',
						'Teams',
					]
				},
			},
			render: ({ r }) => <>
				{r('message')}
				<Divider />
				{r('contact')}
			</>
		}} onChange={onChange} {...pass} />
	</>;
}

const FormOfferPermanent = ({ requirement, onChange, ...pass }) => {
	return <>
		<h2>Make an Offer</h2>
		<Form spec={{
			properties: {
				message: {
					label: 'Make an offer on this candidate',
					type: 'textLong',
				},
				contact: {
					label: 'What is your preferred contact method?',
					type: 'option',
					values: [
						'Email',
						'Telephone',
						'Teams',
					]
				},
			},
			render: ({ r }) => <>
				{r('message')}
				<Divider />
				{r('contact')}
			</>
		}} onChange={onChange} {...pass} />
	</>;
}

const FormReject = ({ onChange, ...pass }) => {
	return <>
		<h2>Reject</h2>
		<Form spec={{
			properties: {
				message: {
					label: 'Why are you rejecting this candidate?',
					type: 'textLong',
				},
				contact: {
					label: 'What is your preferred contact method?',
					type: 'option',
					values: [
						'Email',
						'Telephone',
						'Teams',
					]
				},
			},
			render: ({ r }) => <>
				{r('message')}
				<Divider />
				{r('contact')}
			</>
		}} onChange={onChange} {...pass} />
	</>;
}

const FormMessage = ({ onChange, ...pass }) => {
	return <>
		<h2>Send us a Message</h2>
		<Form spec={{
			properties: {
				message: {
					label: 'What\'s your message?',
					type: 'textLong',
				},
				contact: {
					label: 'What is your preferred contact method?',
					type: 'option',
					values: [
						'Email',
						'Telephone',
						'Teams',
					]
				},
			},
			render: ({ r }) => <>
				{r('message')}
				<Divider />
				{r('contact')}
			</>
		}} onChange={onChange} {...pass} />
	</>;
}

export const RequirementApi = compose(withApi(props => ({
	url: `/requirement-v2:connect/${props.id}`,
	loadingText: 'Loading',
})))(Requirement);

export const Pdf = ({ url }) => {
	const [ pages, setPages ] = useState(null);
	const container = useRef(null);
	const [ width, setWidth ] = useState(null);
	const resize = () => {
        if (container.current) {
            setWidth(container.current.getBoundingClientRect().width);
        }
    };
	useEffect(() => {
		setTimeout(() => {
			resize();
		}, 100);
        window.addEventListener('resize', resize);
	});
	return <>
		<div ref={container}>
			<Document
				loading={<div className="frame"><Loading title="Loading" /></div>}
				error={<div className="frame"><Error error={{ response: { status: 500, data: { status: 'serverError' } } }} /></div>}
				file={url}
				options={pdfOptions}
				onLoadSuccess={({ numPages }) => setPages(numPages)}>
				{Array.from(new Array(pages), (el, i) => (
					<Page key={i}
						width={width}
						renderAnnotationLayer={false}
						pageNumber={i + 1} />
				))}
			</Document>
		</div>
	</>;
}