import React, { Component } from 'react'
// import { Button, Popconfirm } from 'antd'
// import { DeleteOutlined, PlusOutlined, DragOutlined } from '@ant-design/icons'
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// import Box from './Box'

class Stack extends Component {
    handleCreateClick() {
        this.props.onCreate();
    }
    handleDeleteClick(i) {
        this.props.onDelete(i);
    }
    handleDragEnd({ destination, source }) {
        if (destination && source && destination.index !== source.index) {
            this.props.onSort(source.index, destination.index);
        }
    }
    render() {
        if (this.props.mode === 'output' && !this.props.indexes.length) {
            return <span className="none">—</span>;
        }
        return <>
            {/* <DragDropContext
                onDragEnd={result => this.handleDragEnd(result)}>
                <div className={`stack ${this.props.simple ? 'stack__simple' : null} ${this.props.minimal ? 'stack__minimal' : null}`}>
                    <Droppable droppableId="stack" isDropDisabled={!this.props.sort}>
                        {provided => (
                            <div className="stack_list"
                                ref={provided.innerRef}
                                {...provided.droppableProps}>
                                {this.props.indexes.map(i => <>
                                    <Draggable draggableId={`${i}`} index={i} key={i} isDragDisabled={!this.props.sort}>
                                        {provided => (
                                            <div className="stack_entry"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}>
                                                <div>{this.props.r(i)}</div>
                                                {this.props.mode === 'input' && (this.props.sort || this.props.delete) &&
                                                    <div>
                                                        <div className="form-item">
                                                            <label className="form-item_label">&nbsp;</label>
                                                            <div className="form-item_content">
                                                                {this.props.sort &&
                                                                    <Box button icon {...provided.dragHandleProps}>
                                                                        <DragOutlined />
                                                                    </Box>
                                                                }
                                                                {this.props.delete && (
                                                                    this.props.deleteConfirm
                                                                        ? <Popconfirm title={this.props.deleteConfirmText} onConfirm={() => this.handleDeleteClick(i)} ><Button icon={<DeleteOutlined />} className="stack_delete"></Button></Popconfirm>
                                                                        : <Button icon={<DeleteOutlined />} onClick={() => this.handleDeleteClick(i)} className="stack_delete"></Button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                    </Draggable>
                                </>)}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    {this.props.mode === 'input' && this.props.create &&
                        <Button onClick={() => this.handleCreateClick()} icon={<PlusOutlined />} className="stack_create">{this.props.createText}</Button>
                    }
                </div>
            </DragDropContext> */}
        </>;
    }
};
Stack.defaultProps = {
    create: false,
    delete: false,
    deleteConfirm: false,
    deleteConfirmText: 'Are you sure?',
    sort: false,
    simple: false,
    minimal: false,
    createText: 'Add',
    onCreate: () => {},
    onDelete: () => {},
    onSort: () => {},
};
export default Stack;