import React, { useState } from 'react';
import { Router } from "@reach/router"

import logo from 'assets/logo.svg';

import AppContext from 'app/context';
import NotFound from './NotFound';
import Internal from './Internal';

const App = () => {
	const [ presenting, setPresenting ] = useState(false);
	const context = { presenting, setPresenting };
	return <>
		<AppContext.Provider value={context}>
			<div className="page">
				<div className="header">
					<img src={logo} alt="Vector" className="header_logo" />
					{ presenting && <div className="header_inner">
						<a href="https://www.vector-uk.com/hire-people/" rel="noreferrer" target="_blank" className="header_new">Create a new job brief</a>
					</div> }
				</div>
				<div className="main">
					<Router>
						<Internal path="/:auth/*" />
						<NotFound path="/" />
					</Router>
				</div>
			</div>
		</AppContext.Provider>
	</>;
}
export default App;
