import React, { PureComponent } from 'react';

import { DatePicker } from 'antd';
import moment from 'moment';

export default class InputDatetime extends PureComponent {
    handleChange(value) {
        this.props.onChange(value.utc().format('YYYY-MM-DD HH:mm:ss'));
    }
    render() {
        return (
            <DatePicker
                format="LLL"
                style={{ width: '100%' }}
                disabled={this.props.disabled}
                showTime={{ format: 'HH:mm' }}
                onChange={value => this.handleChange(value)}
                value={this.props.value ? moment.utc(this.props.value).local() : null} />
        )
    }
}