import React from 'react';
import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'

import { authState } from 'app/states';
import config from 'app/config';
import Loading from "components/common/Loading";
import Error from "components/common/Error";

const instance = axios.create({ baseURL: config.apiUrl });
export default instance;

export const useAxios = makeUseAxios({ axios: instance });
export const useApi = (options, config) => {
    options.params = {
         ...options.params,
         auth: authState.get(),
    }
    return useAxios(options, config);
};

export const getUri = ({ url, params }) => config.apiUrl + instance.getUri({
    url,
    params: { auth: authState.get(), ...params },
});

export const withApi = (opts) => (Wrapped) => {
    const WithApi = (props) => {
        const { url, params, loadingText } = opts(props);
        const [{ data, loading, error }, refresh ] = useApi({
            url,
            params,
        });
        if (error) {
            return <Error error={error} />;
        } else if (loading) {
            return <Loading title={loadingText} />;
        } else if (!data) {
            return null;
        }
        return <Wrapped {...data.data} refresh={refresh} />;
    }
    return WithApi;
}

export const useLog = ({ action, requirement, link }) => {
    useApi({
        method: 'POST',
        url: 'requirement_connect-log-v2:connect',
        data: {
            records: [
                {
                    action,
                    requirement,
                    link,
                },
            ],
        }
    });
};