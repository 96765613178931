import React, { PureComponent } from 'react';

// import { TokenContext } from 'app/context'
import withOptions from 'helpers/options'

import InputCheckbox from './InputCheckbox';
import InputEmail from './InputEmail';
import InputWeek from './InputWeek';
import InputDate from './InputDate';
import InputTime from './InputTime';
import InputDatetime from './InputDatetime';
import InputDateRange from './InputDateRange';
import InputFile from './InputFile';
import InputPassword from './InputPassword';
import InputRadio from './InputRadio';
import InputLocation from './InputLocation';
import InputSwitch from './InputSwitch';
import InputCards from './InputCards';
import InputCardsMultiple from './InputCardsMultiple';
import InputSelect from './InputSelect';
import InputSelectMultiple from './InputSelectMultiple';
import InputText from './InputText';
import InputColor from './InputColor';
import InputNumber from './InputNumber';
import InputCount from './InputCount';
import InputTextarea from './InputTextarea';
import InputTextrich from './InputTextrich';

export default class Input extends PureComponent {
    // static contextType = TokenContext;
    static typeTypeMap = {
        textLong  : 'textarea',
        boolean   : 'checkbox',
        option    : 'radio',
        optionLong: 'select',
        options   : 'selectMultiple',
        resource  : 'select',
        resources : 'selectMultiple',
    }
    static typeComponentMaps = {
        default: {
            checkbox       : InputCheckbox,
            email          : InputEmail,
            week           : InputWeek,
            date           : InputDate,
            time           : InputTime,
            dateRange      : InputDateRange,
            datetime       : InputDatetime,
            file           : InputFile,
            password       : InputPassword,
            radio          : InputRadio,
            cards          : InputCards,
            cardsMultiple  : InputCardsMultiple,
            select         : InputSelect,
            selectMultiple : InputSelectMultiple,
            location       : InputLocation,
            switch         : InputSwitch,
            text           : InputText,
            color          : InputColor,
            number         : InputNumber,
            count          : InputCount,
            textarea       : InputTextarea,
            textrich       : InputTextrich,
        },
        resource: {
            radio  : withOptions()(InputRadio),
            cards  : withOptions()(InputCards),
            select : withOptions()(InputSelect),
        },
        resources: {
            cardsMultiple: withOptions({multiple: true})(InputCardsMultiple),
            selectMultiple: withOptions({multiple: true})(InputSelectMultiple),
        },
    }
    static nameAutoCompleteMap = {
        email             : 'email',
        password          : 'current-password',
        addressLine1      : 'address-line1',
        addressLine2      : 'address-line2',
        addressLine3      : 'address-line3',
        addressTown       : 'address-level2',
        addressCounty     : 'address-level1',
        addressPostCode   : 'postal-code',
        telephonePrimary  : 'tel',
        telephoneSecondary: 'tel',
    }
    render() {
        const { inputKey, type, kind, input, fallback, ...pass } = this.props;
        let className = this.props.className || '';
        let render;
        if (pass.autoComplete === true && Input.nameAutoCompleteMap[pass.name]) {
            pass.autoComplete = Input.nameAutoCompleteMap[pass.name];
        }
        if (pass.nolabel && !pass.placeholder) {
            pass.placeholder = pass.label;
        }
        if (pass.readonly) {
            pass.disabled = pass.readonly;
            pass.placeholder = null;
        }
        // if (pass.resource && pass.self && this.context.token.user.role === 'customer') {
        //     pass.resource = `${pass.resource}:self`;
        // }
        if (fallback) {
            pass.placeholder = fallback;
        }
        if (input) {
            render = input(pass);
        } else {
            const inputType    = Input.typeTypeMap[type] || type;
            const componentMap = Input.typeComponentMaps[kind] || Input.typeComponentMaps.default;
            const Component    = componentMap[inputType] || InputText;
            render = <Component {...pass} key = {inputKey} />;
        }
        if (fallback) {
            className += ' input-fallback';
        }
        if (className) {
            render = <span className={className}>{render}</span>
        }
        return render;
    }   
}