import React, { PureComponent } from 'react'

import { mergeSpec } from 'helpers/utils';

import Output from './Output'

export default class Graph extends PureComponent {
    render() {
        return Array.isArray(this.props.value)
            ? <GraphArray {...this.props} />
            : <GraphObject {...this.props} />;
    }
}
Graph.defaultProps = {
    renders: {},
};

export class GraphObject extends PureComponent {
    constructor(props) {
        super(props);
        this.record = props.value;
    }
    handleChange = (name, value) => {
        this.props.onChange({
            ...this.props.value,
            [name]: value,
        });
    }
    render() {
        const { parse, render, renderItem, renders, ...pass } = this.props;
        const parser = parse
            || (() => {});
        const renderer = render
            || this.props.renders.object
            || (props => props.blocks.map((name) => props.r(name)));            
        const parsed = mergeSpec(pass, parser(pass));
        return renderer({...parsed,
            r: (name, itemProps) => {
                const props = mergeSpec(parsed.properties[name], itemProps);
                const value = [props.value, this.props.value[name], props.default].find(v => typeof v !== 'undefined');
                return <GraphObjectItem key={name} name={name} render={renderItem}
                    {...props}
                    value={value}
                    record={this.record}
                    renders={this.props.renders}
                    onChange={this.handleChange} />;
            },
        });
    }
}
GraphObject.defaultProps = {
    value: {},
    blocks: [],
    renders: {},
    onChange: () => {},
};

class GraphObjectItem extends PureComponent {
    handleChange = (value) => {
        this.props.onChange(this.props.name, value);
    }
    parser = (props) => ({
        readonly: props.lock && (props.value && props.value.id)
    })
    render() {
        if (this.props.hidden) {
            return null;
        }
        const { kind, traverse = [] } = this.props;
        if (traverse.includes('set')) {
            if (kind === 'resource') {
                return <GraphObject {...this.props}
                    onChange={this.handleChange} />
            } else if (kind === 'resources') {
                return <GraphArray {...this.props}
                    onChange={this.handleChange} />
            }
        }
        const { parse, render, renders, ...pass } = this.props;
        const parser = parse
            || (props => props);
        const renderer =
            render ||
            renders.objectItem ||
            ((props, key) => <Output {...props} key={key} />);
        const parsed = mergeSpec(pass, this.parser(pass), parser(pass));
        return renderer({...parsed,
            onChange: this.handleChange,
        });
    }
}
GraphObjectItem.defaultProps = {
    renders: {},
    onChange: () => {},
};

export class GraphArray extends PureComponent {
    handleChange = (i, value) => {
        this.props.onChange([
            ...this.props.value.slice(0, i),
            value,
            ...this.props.value.slice(i + 1)
        ]);
    }
    handleCreate = () => {
        const item = {
            id: Math.min(0, ...this.props.value.map(v => v.id)) - 1,
            ...this.props.defaultItem,
        };
        if (this.props.behaviours.includes('sortable')) {
            item.sort = this.props.value.length + 1;
        }
        this.props.onChange([
            ...this.props.value,
            item,
        ]);
    }
    handleDelete = (i) => {
        this.props.onChange([
            ...this.props.value.slice(0, i),
            {...this.props.value[i], _: 'delete'},
            ...this.props.value.slice(i + 1)
        ]);
    }
    handleSort = (from, to) => {
        let value = [...this.props.value];
        value.splice(to, 0, value.splice(from, 1)[0]);
        value = value.map((item, i) => ({...item, sort: i + 1}));
        this.props.onChange(value);
    }
    render() {            
        const { render, renderItem, renders, filter, ...pass } = this.props;     
        const renderer = render
            || renders.array
            || (props => props.indexes.map(i => props.r(i)));        
        const filterer = filter
            || (() => true);
        const readonly = typeof pass.readonly === 'object' ? {
            create: false,
            delete: false,
            sort: false,
            ...pass.readonly,
        } : {
            create: pass.readonly,
            delete: pass.readonly,
            sort: pass.readonly,
        };
        return renderer({...pass,
            indexes: pass.value
                .map((value, i) => [value, i])
                .filter(([value, i]) => filterer(value))
                .filter(([value, i]) => value._ !== 'delete')
                .map(([value, i]) => i),
            create: pass.traverse.includes('create') && !readonly.create,
            delete: pass.traverse.includes('delete') && !readonly.delete,
            sort: pass.behaviours.includes('sortable') && !readonly.sort,
            r: (i, itemProps) => {
                const value = pass.value[i];
                const key   = pass.valueSpec.findIndex(item => item.id.toString() === value.id.toString());
                const props = mergeSpec(pass.spec, key !== -1 ? pass.valueSpec[key] : {}, itemProps);
                return <GraphArrayItem key={i} index={i} render={renderItem}
                    {...props}
                    value={value}
                    renders={renders}
                    onChange={this.handleChange} />;
            },
            onCreate: this.handleCreate,
            onDelete: this.handleDelete,
            onSort: this.handleSort,
        });
    }
}
GraphArray.defaultProps = {
    value: [],
    valueSpec: [],
    defaultItem: {},
    traverse: [],
    behaviours: [],
    renders: {},
    onChange: () => {},
};

class GraphArrayItem extends PureComponent {
    handleChange = (value) => {
        this.props.onChange(this.props.index, value);
    }
    render() {
        return <GraphObject {...this.props} 
            onChange={this.handleChange} />
    }
}
GraphArrayItem.defaultProps = {
    renders: {},
    onChange: () => {},
};