import React, { PureComponent } from 'react';
import memoizeOne from 'memoize-one'

import { Radio as AntRadio } from 'antd';
import { fromString, toString } from 'helpers/utils';

class InputRadio extends PureComponent {
    options = memoizeOne((values, labels) => {
        return values.map((v) => ({
            value: v,
            label: this.props.output
                ? this.props.output({value: v})
                : (labels[v] || v),
        }));
    })
    handleChange = (ev) => {
        this.props.onChange(fromString(ev.target.value, this.props.values));
    }
    render() {
        const { values, labels, value, disabled } = this.props;
        return (
            <AntRadio.Group
                disabled={disabled}
                onChange={this.handleChange}
                value={toString(value)}>
                {this.options(values, labels).map(option => (
                    <AntRadio
                        key={toString(option.value)}
                        value={toString(option.value)}>
                        {option.label}
                    </AntRadio>
                ))}
            </AntRadio.Group>
        )
    }
}
InputRadio.defaultProps = {
    values: [],
    labels: {},
};
export default InputRadio;