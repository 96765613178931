import React, { PureComponent } from 'react';

import { Checkbox as AntCheckbox } from 'antd';

export default class InputCheckbox extends PureComponent {
    handleChange(value) {
        this.props.onChange(value);
    }
    render() {        
        return (
            <AntCheckbox
                disabled={this.props.disabled}
                checked={this.props.value}
                onChange={(ev) => this.handleChange(ev.target.checked)}>
                {this.props.label}
            </AntCheckbox>                
        )
    }
}