import React, { Component } from 'react';
import _ from 'lodash'

// import { StoreContext } from 'app/context'
// import api from 'utils/api'

// import Modal from 'components/common/modal'

const withOptions = ({
    multiple = false,
} = {}) => (Wrapped) => {
    class WithOptions extends Component {
        // static contextType = StoreContext;
        constructor(props) {
            super(props);
            this.state = {
                records: [],
                createOpen: false,
            };
            this.search = null;
            this.timeout = null;
        }
        values = () => {
            const values = !multiple
                ? [...this.state.records, ...(this.props.value ? [this.props.value] : [])]
                : [...this.state.records, ...(this.props.value ? this.props.value : [])];
            return _.uniqBy(values, v => v.id).filter(this.props.filter);
        }
        import() {
            // if (this.context.stores[this.props.resource]) {
            //     this.setState({
            //         records: this.context.stores[this.props.resource],
            //     });
            // }
        }
        fetch(params = {}) {
            // api({
            //     path: `${this.props.resource}`,
            //     query: {...params, ...this.props.query},
            // }).then(res => {
            //     this.setState({
            //         records: res.data.records,
            //     });
            // });
        }
        componentDidMount() {
            if (this.props.preload) {
                this.fetch({limit: null});
            } else {
                this.import();
            }
        }
        handleSearch = (search) => {
            this.search = search;
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            if (!search) {
                return;
            }
            this.timeout = setTimeout(() => {
                this.fetch({limit: 20, search});
            }, 200);
        }
        handleCreateOpen = () => {
            this.setState({
                createOpen: true,
            });
        }
        handleCreateClose = () => {
            this.setState({
                createOpen: false,
            });
        }
        handleCreate = (record) => {
            this.setState({
                records: [...this.state.records, record],
            }, () => {
                if (!multiple) {
                    this.props.onChange(record);
                } else {
                    this.props.onChange([...this.props.value, record]);
                }
            });
        }
        render() {
            const { createComponent: CreateComponent, ...pass } = this.props;
            return <>
                <Wrapped {...pass}
                    search={true}
                    values={this.values()}
                    onCreate={this.handleCreateOpen} 
                    onSearch={this.handleSearch} />
                {/* {this.state.createOpen &&
                    <Modal
                        onClose={this.handleCreateClose}>
                        <CreateComponent onSave={({ record }) => {
                            this.handleCreateClose();
                            this.handleCreate(record);
                        }} />
                    </Modal>
                } */}
            </>
        }
    };
    WithOptions.defaultProps = {
        create: false,
        preload: false,
        search: false,
        filter: () => true,
        output: !multiple
            ? ({ value }) => value.name || value.id
            : ({ value }) => value.map(v => v.name || v.id),
    };
    return WithOptions;
}
export default withOptions;