import React, { PureComponent } from 'react';
import filesize from 'filesize';
// import { message } from 'antd';
import { Upload, Button } from 'antd';
// import { apiParams, apiXhr } from 'utils/api';
import { UploadOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

class InputFile extends PureComponent {
    constructor(props) {
        super(props); 
        this.input = React.createRef();
        this.state = {
            message: null,
            progress: 0,
        };
    }
    handleChange = (value) => {
        this.props.onChange(value);
    }
    handleDelete = () => {
        this.props.onChange(null);
    }
    customRequest({file}) {
        // apiXhr({
        //     path: '/upload',
        //     method: 'post',
        //     body: file,
        // }, {
        //     uploadStart: (ev) => {
        //         this.setState({
        //             message: `Uploading…`,
        //         });
        //     },
        //     uploadEnd: (ev) => {
        //         this.setState({
        //             message: `Processing…`,
        //         });
        //     },
        //     uploadProgress: (ev) => {          
        //         this.setState({
        //             message: `Uploading… ${Math.round((ev.loaded / ev.total) * 100)}%`,
        //         });      
        //     },
        //     success: (ev, res) => {
        //         const text = 'File uploaded successfully';
        //         message.success(text);
        //         this.setState({
        //             message: null,
        //         });  
        //         this.handleChange(res.data.record);
        //     },
        //     error: (ev, value) => {
        //         const text = {
        //             invalid_size: 'File exceeds the maximum size',
        //             invalid_type: 'File must be a PDF',
        //         }[value.status];
        //         this.setState({
        //             message: text,
        //         });  
        //         message.error(text);
        //     },
        // });
    }
    render() {
        return (
            <div class="row row__gap row__middle">
                <Upload
                    showUploadList={false}
                    customRequest={(data) => this.customRequest(data)}
                    // action={apiParams({path: `/file`}).url}
                    >
                    {!this.props.disabled &&
                        <Button icon={<UploadOutlined />}>
                            Upload File
                        </Button>
                    }
                </Upload>
                {this.state.message ? <>
                    <span>{this.state.message}</span>
                </> : <>
                    {this.props.value ? <>
                        {!this.props.value.isUpload && this.props.download
                            ? <a href={this.props.download({value: this.props.value})}>
                                <DownloadOutlined />
                                &nbsp;
                                Download ({filesize(this.props.value.size)})
                              </a>
                            : <span>File Uploaded</span> }
                        <DeleteOutlined onClick={this.handleDelete} />
                    </> : <>
                        <span class="note">No File</span>
                    </>}
                </>}
            </div>
        )
    }
}
InputFile.defaultProps = {
    disabled: false,
    download: null,
};
export default InputFile;