import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd'
import en_GB from 'antd/es/locale/en_GB';
import moment from 'moment'
import 'moment/locale/en-gb';

import App from 'components/App';

import 'assets/styles/antd.less'
import 'assets/styles/styles.scss'

moment.locale('en-gb');

ReactDOM.render(
    <ConfigProvider locale={en_GB}>
        <App />
    </ConfigProvider>
    ,
    document.getElementById('root')
);