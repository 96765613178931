import React from 'react';
import { Result } from 'antd';

const Error = ({ error, title, subTitle }) => {
	const messages = {
		notFound: 'Sorry, the record you requested could not be found',
		serverError: 'Sorry, an error occured trying to load this record',
		forbidden: 'Sorry, you do not have permission to access this record',
	};
	return <Result
		status="error"
		title={error ? error.response.status : title}
		subTitle={error ? (messages[error.response.data.status] || error.message) : subTitle} />;
}
export default Error;