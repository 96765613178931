/* eslint-disable no-template-curly-in-string */

import React, { Component } from 'react';
import { Popover, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons'
import { template } from 'lodash'

export default class FormItem extends Component {
    messages = {
        booleanType       : template('Please select either yes or no'),
        decimalType       : template('Please enter a number'),
        floatType         : template('Please enter a number'),
        emailAddress      : template('Please enter a valid email address'),
        hostname          : template('Please enter a valid hostname'),
        integerType       : template('Please enter a whole number'),
        length_min        : template('Please enter at least ${min} characters'),
        length_max        : template('Please enter no more than ${max} characters'),
        password_lowercase: template('Please enter at least ${lower} lowercase letters'),
        password_uppercase: template('Please enter at least ${upper} uppercase letters'),
        password_digit    : template('Please enter at least ${digit} digits'),
        password_special  : template('Please enter at least ${special} special characters'),
        range_min         : template('Please enter at least ${min}'),
        range_max         : template('Please enter no more than ${max}'),
        set               : template('Please complete this field'),
        dateTime          : template('Please enter a valid date/time'),
        count_min         : template('Please add at least ${min} items'),
        count_max         : template('Please add no more than ${max} items'),
        dateTime_monday   : template('Please select a monday'),
        url               : template('Please enter a valid URL'),
        in                : template('Please enter one of the allowed values'),
        recaptcha_connect : template('Sorry, we could not verify you, please try again'),
        recaptcha_invalid : template('Sorry, we could not verify you, please try again'),
    }
    render() {
        let info = this.props.errors.map(error => {
            const key = error[1] ? `${error[0]}_${error[1]}` : `${error[0]}`;            
            return this.messages[key]
                ? this.messages[key](error[2])
                : key;
        });
        if (this.props.help) {
            info.push(this.props.help);
        }
        return (
            <div
                className={`
                    form-item
                    ${(this.props.type === 'resources' && this.props.traverse.includes('set')) ? 'form-item__stack' : ''}
                    ${(this.props.mode === 'input') ? 'is-input' : ''}
                    ${(this.props.validator.set || this.props.validator.count) ? 'is-required' : 'is-optional'}
                    ${(this.props.errors.length > 0) ? 'is-error' : ''}
                    ${(this.props.itemClass) ? this.props.itemClass : ''}
                `}
                style={{
                    width: this.props.width,
                    flex: this.props.flex,
                }} >
                {!this.props.nolabel && 
                    <label className="form-item_label"
                        style={{
                            textAlign: this.props.align,
                        }}>
                        {this.props.type !== 'boolean' || this.props.mode === 'output' ? (this.props.label || this.props.name) : ''}
                        {this.props.pop &&
                            <Popover title={this.props.pop[0]} content={this.props.pop[1]} trigger="hover">
                                {"\u00a0\u00a0"}<QuestionCircleFilled style={{color: '#ccc'}} />
                            </Popover>
                        }
                    </label>
                }
                <div className="form-item_content">
                    {!this.props.noinfo ? <>
                        {this.props.children}
                        <div className="form-item_info">
                            {info.reduce((result, item) => <>{result}{item}<br/></>, '')}
                        </div>
                    </> : <>
                        <Tooltip title={info.reduce((result, item) => <>{result}{item}<br/></>, '')} trigger="focus" placement="bottom">
                            {this.props.children}                        
                        </Tooltip>
                    </>}
                </div>
            </div>
        )
    }
}
FormItem.defaultProps = {
    validator: {},
    errors: [],
};