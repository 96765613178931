import React, { PureComponent } from 'react';

import { Input as AntInput } from 'antd';

export default class InputNumber extends PureComponent {
    handleChange(ev) {
        this.props.onChange(ev.target.value);
    }
    render() {        
        return (
            <AntInput
                style={{minWidth: this.props.width, textAlign: this.props.align}}
                // step={this.props.step}
                // min={this.props.min}
                // max={this.props.max}
                className={`
                    'input-number'
                    ${this.props.cross && 'input-cross'}
                `}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
                disabled={this.props.disabled}
                value={this.props.value}
                placeholder={this.props.placeholder}
                prefix={this.props.prefix}
                suffix={this.props.suffix}
                // precision={this.props.precision}
                onChange={(value) => this.handleChange(value)}  />
        )
    }
}
InputNumber.defaultProps = {
    onFocus: () => {},
    onBlur: () => {},
    cross: false,
};