const config = {
    'localhost:3011': {
        basePath: '',
        apiUrl: 'http://vectorconnect.test/api',
    },
    'connecttest.vector-uk.com': {
        basePath: '',
        apiUrl: 'https://connecttest.vector-uk.com/api',
    },
    'connect.vector-uk.com': {
        basePath: '',
        apiUrl: 'https://connect.vector-uk.com/api',
    },
}[window.location.host];
export default config;