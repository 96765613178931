import React, { useEffect } from 'react';
import { useState } from '@hookstate/core';
import Hashids from 'hashids'

import { authState } from 'app/states';
import { RequirementApi } from './Requirement';

const Internal = ({ auth }) => {
	useEffect(() => {
		authState.set(auth);
	}, [auth]);
	const [ hid ] = auth.split('-');
	const [ id ] = new Hashids().decode(hid);
	const state = useState(authState);
	if (!state.get()) {
		return null;
	}
	return <RequirementApi id={id} />;
}
export default Internal;
