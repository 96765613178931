import React, { PureComponent } from 'react';

class InputCount extends PureComponent {
    render() {
        if (1 === 2) {
            return <div></div>;
        }
        if (this.props.value.length === 1) {
            return <>
                {this.props.value[0].name}
            </>;
        } else {
            return <>
                {this.props.value.length} {this.props.description}
            </>;
        }
    }
}
InputCount.defaultProps = {
    description: 'Items',
};
export default InputCount;