import React, { PureComponent } from 'react';

import { Input as AntInput } from 'antd';

class InputTextarea extends PureComponent {
    handleChange(ev) {
        this.props.onChange(ev.target.value);
    }
    render() {
        return (
            <AntInput.TextArea
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                autoSize={{ minRows: 6, maxRows: 10 }}
                value={this.props.value !== null ? this.props.value : ''}
                onChange={(ev) => this.handleChange(ev)} />
        )
    }
}
InputTextarea.defaultProps = {
    onFocus: () => {},
    onBlur: () => {},
};
export default InputTextarea;