import React, { PureComponent } from 'react';

// import ColorPicker from 'rc-color-picker';

export default class InputColor extends PureComponent {
    handleChange(value) {        
        this.props.onChange(value);
    }
    render() {
        return <>
            {/* <ColorPicker
                defaultColor="#ffffff"
                enableAlpha={false}
                color={this.props.value}
                onChange={(color) => this.handleChange(color.color)} /> */}
        </>
    }
}
InputColor.defaultProps = {};