import React, { PureComponent } from 'react';

import { Input } from 'antd';

export default class InputEmail extends PureComponent {
    handleChange(value) {
        this.props.onChange(value);
    }
    render() {
        return (
            <Input
                type="email"
                disabled={this.props.disabled}
                value={this.props.value}
                placeholder={this.props.placeholder}
                onChange={(ev) => this.handleChange(ev.target.value)}  />
        )
    }
}