import React from 'react'
import { Button } from 'antd'
import moment from 'moment'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

import { currency } from 'helpers/utils'

const sub = (value, props) => {
    if (value === null || value === '' || typeof value === 'undefined' || (Array.isArray(value) && !value.length)) {
        if (props.fallback) {
            return props.fallback;
        } else {
            return <span className="none">—</span>;
        }
    }
    return value;
}

const Output = (props) => {
    const typeTypeMap = {
        resource : 'object',
        resources: 'objects',
    }
    const typeComponentMap = {
        text      : OutputText,
        textLong  : OutputText,
        number    : OutputNumber,
        boolean   : OutputBoolean,
        option    : OutputOption,
        optionLong: OutputOption,
        options   : OutputOptions,
        object    : OutputObject,
        objects   : OutputObjects,
        datetime  : OutputDateTime,
        date      : OutputDate,
        time      : OutputTime,
        week      : OutputWeek,
        currency  : OutputCurrency,
        password  : OutputPassword,
    };
    const { type, output, pseudo, fallback, ...pass } = props;
    let className = props.className || '';
    let render;
    if (output) {
        render = sub(output(pass), props);
    } else {
        const inputType = typeTypeMap[type] || type;
        const Component = typeComponentMap[inputType] || OutputText;
        render = <Component {...pass} />;
    }
    if (pseudo) {
        render = <OutputPseudo {...pass}>{render}</OutputPseudo>;
    }
    if (className) {
        render = <span className={className}>{render}</span>
    }
    render = <OutputShim>{render}</OutputShim>;
    return render;
}
export default Output;

export const OutputText = (props) => (
    sub(props.value, props)
);

export const OutputNumber = (props) => (
    sub(props.value, props)
);

export const OutputOption = (props) => (
    sub(props.labels[props.value] ? props.labels[props.value] : props.value, props)
);

export const OutputOptions = (props) => (
    sub(props.value.map((v) => props.labels[v] ? props.labels[v] : v).join(', '), props)
);

export const OutputObject = (props) => (
    sub(props.value ? props.value.name || props.value.id : null, props)
);

export const OutputObjects = (props) => (
    sub(props.value.map(v => v ? v.name || v.id : null).join(', '), props)
);

export const OutputBoolean = (props) => (
    sub(props.value ? <CheckOutlined /> : <CloseOutlined style={{color: '#ccc'}} />, props)
);

export const OutputDateTime = (props) => (
    sub(moment.utc(props.value).local().format('LLLL'), props)
);

export const OutputDate = (props) => (
    sub(moment(props.value).format('LL'), props)
);

export const OutputTime = (props) => (
    sub(moment(props.value, 'H:m:s').format('LT'), props)
);

export const OutputWeek = (props) => (
    sub(moment(props.value).format('LL'), props)
);

export const OutputCurrency = (props) => (
    sub(currency(props.value), props)
);

export const OutputPseudo = (props) => (
    sub(<Button disabled size={props.size}>{props.children}</Button>, props)
);

export const OutputPassword = (props) => (
    sub('••••••••••••', props)
);

export const OutputShim = (props) => (
    <span className="output-shim" style={{minWidth: props.width, flex: props.flex, textAlign: props.align}}>{props.children}</span>
);
