import React, { PureComponent } from 'react';
import { Select as AntSelect, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import memoizeOne from 'memoize-one'
import { toString, fromString } from 'helpers/utils';

class InputSelectMultiple extends PureComponent {
    options = memoizeOne((values, labels) => {
        return values.map((v) => ({
            value: v,
            label: this.props.output
                ? this.props.output({value: [v]})
                : (labels[v] || v),
        }));
    })
    handleChange = (value) => {
        this.props.onChange(value.map(v => fromString(v, this.props.values)));
    }
    handleCreate = () => {
        this.props.onCreate();
    }
    handleSearch = (search) => {
        this.props.onSearch(search);
    }
    render() {
        const { values, labels, value, disabled, placeholder, search } = this.props;
        return (
            <div className="row row__merge">
                <AntSelect
                    style={{flex: 1}}
                    allowClear
                    showSearch={search}
                    filterOption={false}
                    showArrow={true}
                    mode="multiple"
                    value={value ? value.map(v => toString(v)) : undefined}
                    disabled={disabled}
                    placeholder={placeholder}
                    onSearch={search ? this.handleSearch : undefined}
                    onChange={this.handleChange}>
                    {this.options(values, labels).map(option => (
                        <AntSelect.Option 
                            key={toString(option.value)}
                            value={toString(option.value)}>
                            {option.label}
                        </AntSelect.Option>
                    ))}
                </AntSelect>
                {this.props.create && <>
                    <Button icon={<PlusOutlined />} onClick={this.handleCreate}>Add New</Button>
                </>}
            </div>
            
        )
    }
}
InputSelectMultiple.defaultProps = {
    values: [],
    create: false,
    search: false,
    onCreate: () => {},
    onSearch: () => {},
};
export default InputSelectMultiple;