import React from 'react';
import { Result } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'

const Loading = ({
	title = 'Loading',
	subTitle = 'Please wait…',
}) => {
	return <Result
		icon={<LoadingOutlined style={{color: '#8bb228'}} />}
		title={title}
		subTitle={subTitle} />;
}
export default Loading;