import React, { Component } from 'react'

import { mergeSpecMemo } from 'helpers/utils'

import FormItem from './FormItem'
import FormShim from './FormShim'
import Stack from './Stack'
import StackRows from './StackRows'
import StackPlain from './StackPlain'
import Input from './Input'
import Output from './Output'
import Graph from './Graph'
import Spin from './Spin'

class Form extends Component {
    renders = {
        objectItem: (props) => {
            const mode   = props.mode || this.props.mode;
            const layout = props.layout || this.props.layout;
            const size   = props.size || this.props.size;
            if (layout === 'inline') {
                return <>
                    {mode === 'input' ? <>
                        <Input {...props} size={size} />
                    </> : <>
                        <Output {...props} size={size} />
                    </>}
                </>;
            } else {
                return <>
                    <FormItem {...props} key={props.name} mode={mode} size={size}>
                        {mode === 'input' ? <>
                            <Input {...props} size={size} />
                        </> : <>
                            {this.props.mode === 'output' ? <>
                                <Output {...props} size={size} />
                            </> : <>
                                <FormShim><Output {...props} size={size} /></FormShim>                            
                            </>}
                        </>}
                    </FormItem>
                </>
            }
        },
        array: (props) => {
            const mode   = props.mode || this.props.mode;
            const layout = props.layout || this.props.layout;
            const size   = props.size || this.props.size;
            if (layout === 'shim') {
                return props.indexes.length ? props.r(0, {...props, mode}) : null;
            } else if (layout === 'plain') {
                return <>
                    <StackPlain {...props} mode={mode} size={size} />
                </>;
            } else if (layout === 'rows') {
                return <>
                    <StackRows {...props} mode={mode} size={size} />
                </>;
            } else {
                return <>
                    <FormItem {...props} key={props.name} mode={mode} size={size}>
                        <Stack {...props} mode={mode} size={size} />
                    </FormItem>
                </>;
            }
        },
    }
    handleChange = (value) => {
        this.props.onChange(value);
    }
    render() {
        const { mode, loading, spin, spec, errors, ...pass } = this.props;
        if (mode === 'input' && spin === true) {     
            return (
                <Spin spinning={loading === true}>
                    <Graph {...pass}
                        {...mergeSpecMemo(spec, errors)}
                        renders={this.renders}
                        onChange={this.handleChange} />
                </Spin>
            );
        } else {
            return (
                <Graph {...pass}
                    {...mergeSpecMemo(spec, errors)}
                    renders={this.renders}
                    onChange={this.handleChange} />
            );            
        }
    }
}
Form.defaultProps = {
    spin: true,
    value: {},
    layout: 'standard',
    mode: 'input',
    size: undefined,
    onChange: () => {},
};
export default Form;