
import _ from 'lodash'
import memoizeOne from 'memoize-one'
// import qs from 'qs';

export const compose = (...args) => _.flowRight(...args);

// export const displayName = (Wrapped) => {
//     return Wrapped.displayName ||  Wrapped.name || 'Component';
// }

// export const diff = (nextObject = {}, prevObject = {}, { properties }) => (
//     Object.fromEntries(Object.keys(nextObject)
//         .map(name => {
//             let value;
//             const nextValue = nextObject[name];
//             const prevValue = prevObject[name];
//             if (name !== 'id' && nextValue === prevValue) {
//                 return undefined;
//             }
//             const { kind, traverse = [], spec } = properties[name] || {};            
//             if (traverse.includes('set')) {
//                 if (kind === 'resource') {
//                     value = diff(nextValue, prevValue, spec);
//                 } else if (kind === 'resources') {
//                     value = nextValue.map(nextItem => {
//                         const key = typeof prevValue !== 'undefined'
//                             ? prevValue.findIndex(prevItem => prevItem.id.toString() === nextItem.id.toString())
//                             : -1;
//                         const prevItem = key !== -1 ? prevValue[key] : {};
//                         if (nextItem === prevItem) {
//                             return undefined;
//                         }
//                         return diff(nextItem, prevItem, spec)
//                     }).filter(v => typeof v !== 'undefined');
//                 }
//             } else {
//                 if (kind === 'resource' && typeof nextValue !== 'undefined') {
//                     value = nextValue !== null ? nextValue.id : nextValue;
//                 } else if (kind === 'resources') {
//                     value = nextValue.map(nextItem => {
//                         return typeof nextItem !== 'undefined' ? nextItem.id : undefined;
//                     }).filter(v => typeof v !== 'undefined');
//                 } else {
//                     value = nextValue;
//                 }
//             }
//             return [name, value];
//         })
//         .filter(v => typeof v !== 'undefined'))
// );

export const mergeSpec = (...specs) => _.mergeWith({}, ...specs, (curValue, newValue) => {
    if (Array.isArray(newValue)) {
        return newValue;
    }
});
export const mergeSpecMemo = memoizeOne((...specs) => mergeSpec(...specs));

// export const active = (items, path) => {
//     const item = items.find(v => v.key === '' ? path === '' : _.startsWith(path, v.key));
//     return item ? item.key : null;
// }

// export const uniqueParts = (a, b, delimiter) => {
//     const as = a.split(' ');
//     const bs = b.split(' ');
//     for (let i = 0; i < bs.length; i++) {
//         if (as[i] === bs[i]) {
//             as[i] = null;
//         }
//     }
//     return `${as.filter(v => v !== null).join(' ')}${delimiter}${bs.join(' ')}`;
// }

export const currency = (number) => new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(number);
export const currencySymbol = () => new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).formatToParts()[0].value;

// export const query = (string) => qs.parse(string, {ignoreQueryPrefix: true});

// export const id = (record) => typeof record !== 'undefined' ? (record.id > 0 ? record.id : undefined) : undefined;

export const toString = (value) => {
    if (typeof value === 'object' && value !== null && value.constructor === Object) {
        return value.id.toString();
    } else if (typeof value !== 'undefined' && value !== null) {
        return value.toString();
    } else {
        return undefined;
    }
};

export const fromString = (value, values) => {
    if (value !== '') {
        return values.find(v => toString(v) === value);
    } else {
        return null;
    }
};

// export const visibleMealIds = (meals, customers) => {
//     const ids = [];
//     for (const meal of meals) {
//         let isVisible = meal.isVisible;
//         for (const customer of customers) {
//             for (const mealTerm of customer.mealTerms) {
//                 if (mealTerm.meal.id === meal.id && mealTerm.isVisible !== null) {
//                     isVisible = mealTerm.isVisible;
//                     break;
//                 }
//             }
//         }
//         if (isVisible) {
//             ids.push(meal.id);
//         }
//     }
//     return ids;
// };

// export const visibleMealSizeIds = (sizes, customers) => {
//     const ids = [];
//     for (const size of sizes) {
//         let isVisible = size.isVisible;
//         for (const customer of customers) {
//             for (const mealTerm of customer.mealTerms) {
//                 for (const sizeTerm of mealTerm.sizeTerms) {
//                     if (sizeTerm.size.id === size.id && sizeTerm.isVisible !== null) {
//                         isVisible = sizeTerm.isVisible;
//                         break;
//                     }
//                 }
//             }
//         }
//         if (isVisible) {
//             ids.push(size.id);
//         }
//     }
//     return ids;
// };

// export const visibleProductIds = (products, customers) => {
//     const ids = [];
//     for (const product of products) {
//         let isVisible = product.isVisible;
//         for (const customer of customers) {
//             for (const productTerm of customer.productTerms) {
//                 if (productTerm.product.id === product.id && productTerm.isVisible !== null) {
//                     isVisible = productTerm.isVisible;
//                     break;
//                 }
//             }
//         }
//         if (isVisible) {
//             ids.push(product.id);
//         }
//     }
//     return ids;
// };