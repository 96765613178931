import React, { PureComponent } from 'react';

import { DatePicker } from 'antd';
import moment from 'moment';

export default class InputDate extends PureComponent {
    handleChange(value) {
        this.props.onChange(value.format('YYYY-MM-DD'));
    }
    render() {
        return (
            <DatePicker
                format="LL"
                style={{ width: '100%' }}
                disabled={this.props.disabled}
                onChange={value => this.handleChange(value)}
                value={this.props.value ? moment(this.props.value) : null} />
        )
    }
}