import React, { PureComponent } from 'react';

import { Input, Select } from 'antd';

export default class InputLocation extends PureComponent {
    handleChange(i, value) {
        const data = this.props.value ? this.props.value : [null, 10];
        data[i] = value;
        this.props.onChange(data);
    }
    render() {
        return (
            <Input
                disabled={this.props.disabled}
                value={this.props.value ? this.props.value[0] : null}
                placeholder={this.props.placeholder}
                onChange={(ev) => this.handleChange(0, ev.target.value)}
                addonAfter={<>
                    <Select
                        style={{width: '8em'}}
                        value={this.props.value ? this.props.value[1] : 10}
                        onChange={(value) => this.handleChange(1, value)}>
                        <Select.Option value={10}>10 miles</Select.Option>
                        <Select.Option value={20}>20 miles</Select.Option>
                        <Select.Option value={30}>30 miles</Select.Option>
                        <Select.Option value={40}>40 miles</Select.Option>
                        <Select.Option value={50}>50 miles</Select.Option>
                    </Select>
                </>} />
        )
    }
}