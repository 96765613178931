import React, { PureComponent } from 'react';

import { TimePicker } from 'antd';
import moment from 'moment';

export default class InputTime extends PureComponent {
    handleChange(value) {
        this.props.onChange(value.format('HH:mm:ss'));
    }
    render() {
        return (
            <TimePicker
                format="HH:mm"
                disabled={this.props.disabled}
                onChange={value => this.handleChange(value)}
                value={this.props.value ? moment(this.props.value, 'HH:mm:ss') : null} />
        )
    }
}