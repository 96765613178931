import React, { PureComponent } from 'react';
import memoizeOne from 'memoize-one'

import { Card } from 'antd';
import { toString } from 'helpers/utils';

class InputCardsMultiple extends PureComponent {
    options = memoizeOne((values, labels) => {
        return values.map((v) => ({
            value: v,
            label: this.props.output
                ? this.props.output({value: [v]})
                : (labels[v] || v),
        }));
    })
    handleAdd = (item) => {
        this.props.onChange([...(this.props.value || []), item]);
    }
    handleRemove = (item) => {
        this.props.onChange(this.props.value.filter(v => toString(v) !== toString(item)));
    }
    render() {
        const { values, labels, value, size, align } = this.props;
        if (!values.length) {
            return (
                <div className="col col__gap-small">
                    <Card disabled className="select">—</Card>
                </div>
            );
        }
        const strings = value ? value.map(v => toString(v)) : [];
        return <>
            <div className="col col__gap-small">
                {this.options(values, labels).map(option => {
                    const isSelected = strings.includes(toString(option.value));
                    return (
                        <Card
                            className={`select select__${size} ${isSelected ? 'is-selected' : ''}`}
                            style={{textAlign: align}}
                            key={toString(option.value)}
                            onClick={isSelected ? () => this.handleRemove(option.value) : () => this.handleAdd(option.value)}>
                            {option.label}
                        </Card>
                    );
                })}
            </div>
        </>;
    }
}
InputCardsMultiple.defaultProps = {
    values: [],
    labels: {},
};
export default InputCardsMultiple;