import React, { Component } from 'react'

class StackPlain extends Component {
    handleDeleteClick(i) {
        this.props.onDelete(i);
    }
    render() {
        const { indexes, delete: del } = this.props;
        return <>
            {indexes.map(i => <>
                {this.props.r(i, {
                    delete: del,
                    onDelete: () => this.handleDeleteClick(i),
                })}
            </>)}
        </>;
    }
};
StackPlain.defaultProps = {
    delete: false,
    deleteConfirm: false,
    onDelete: () => {},
};
export default StackPlain;