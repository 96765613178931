import React, { Component } from 'react'
import { Button, Popconfirm } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

class StackRows extends Component {
    handleCreateClick() {
        this.props.onCreate();
    }
    handleDeleteClick(i) {
        this.props.onDelete(i);
    }
    render() {
        const { mode, indexes, create, delete: del, deleteConfirm, deleteConfirmText, deleteIcon, before, after, extra, createText } = this.props;
        return <>
            {before}
            {indexes.map(i => <>
                <tr>
                    {this.props.r(i)}
                    {mode === 'input' && del &&
                        <td className="print-hidden">
                            {deleteConfirm
                                ? <Popconfirm title={deleteConfirmText} onConfirm={() => this.handleDeleteClick(i)} ><Button icon={deleteIcon} className="stack_delete"></Button></Popconfirm>
                                : <Button icon={deleteIcon} onClick={() => this.handleDeleteClick(i)} className="stack_delete"></Button>}
                        </td>
                    }
                </tr>
            </>)}
            {after}
            <tr>
                <td className="print-invisible">
                    {mode === 'input' && create &&
                        <Button onClick={() => this.handleCreateClick()} icon={<PlusOutlined />} className="stack_create">{createText}</Button>
                    }
                </td>
                {extra}
            </tr>
        </>;
    }
};
StackRows.defaultProps = {
    colCount: 0,
    create: false,
    createText: 'Add',
    delete: false,
    deleteIcon: <DeleteOutlined />,
    deleteConfirm: false,
    deleteConfirmText: 'Are you sure?',
    onCreate: () => {},
    onDelete: () => {},
};
export default StackRows;