import React, { PureComponent } from 'react';

import { DatePicker } from 'antd';
import moment from 'moment';

export default class InputDateRange extends PureComponent {
    handleChange(value) {
        this.props.onChange(value.map(v => v.format('YYYY-MM-DD')));
    }
    render() {
        return (
            <DatePicker.RangePicker
                onChange={value => this.handleChange(value)}
                value={this.props.value ? this.props.value.map(v => v ? moment(v) : null) : null} />
        )
    }
}