import React, { Component } from 'react'

import { Spin as AntSpin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'

export default class Spin extends Component {
    render() {
        const {size, colour, ...pass} = this.props;
        return <AntSpin {...pass}
            indicator={<LoadingOutlined style={{color: colour, fontSize: size}} />} />
    }
}
Spin.defaultProps = {
    size: '2em',
}