import React, { PureComponent } from 'react';

// import ReactQuill from 'react-quill'

export default class InputTextrich extends PureComponent {
    modules = {
        toolbar: [
            ['bold', 'italic'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            // ['link'],
            // ['clean']
        ],
    }
    handleChange = (value) => {
        this.props.onChange(value);
    }
    render() {
        return <>
            {/* <ReactQuill
                disabled={this.props.disabled}
                defaultValue={this.props.value}
                modules={this.modules}
                // onFocus={this.props.onFocus}
                // onBlur={this.props.onBlur}
                onChange={this.handleChange}  /> */}
        </>;
    }
}
InputTextrich.defaultProps = {
    // onFocus: () => {},
    // onBlur: () => {},
};