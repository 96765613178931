import React, { PureComponent } from 'react';
import memoizeOne from 'memoize-one'
import { Select as AntSelect } from 'antd';

import { toString, fromString } from 'helpers/utils'

class InputSelect extends PureComponent {
    options = memoizeOne((values, labels) => {
        return values.map((v) => ({
            value: v,
            label: this.props.output
                ? this.props.output({value: v})
                : (labels[v] || v),
        }));
    })
    handleChange = (value) => {
        this.props.onChange(fromString(value, this.props.values));
    }
    handleSearch = (search) => {
        this.props.onSearch(search);
    }
    render() {
        const { values, labels, value, disabled, placeholder, search } = this.props;
        return (
            <AntSelect
                style={{minWidth: this.props.width, maxWidth: this.props.width}}
                allowClear
                showSearch={search}
                filterOption={false}
                showArrow={true}
                mode="default"
                value={toString(value)}
                disabled={disabled}
                placeholder={placeholder}
                onSearch={search ? this.handleSearch : undefined}
                onChange={this.handleChange}>
                {this.options(values, labels).map(option => (
                    <AntSelect.Option 
                        key={toString(option.value)}
                        value={toString(option.value)}>
                        {option.label}
                    </AntSelect.Option>
                ))}
            </AntSelect>
        )
    }
}
InputSelect.defaultProps = {
    values: [],
    labels: {},
    search: false,
    onSearch: () => {},
};
export default InputSelect;