import React, { PureComponent } from 'react';

import { DatePicker } from 'antd';
import moment from 'moment';

export default class InputWeek extends PureComponent {
    handleChange(value) {
        this.props.onChange(value.startOf('week').format('YYYY-MM-DD'));
    }
    render() {
        return (
            <DatePicker.WeekPicker
                placeholder="Select week"
                format="LL"
                disabled={this.props.disabled}
                onChange={value => this.handleChange(value)}
                value={this.props.value ? moment(this.props.value) : null} />
        )
    }
}